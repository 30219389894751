import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
// import Text from "common/src/components/Text";
// import Heading from "common/src/components/Heading";
import Container from 'common/src/components/UI/Container';
import ParticlesWebComponent from '../../ParticleWeb';
import BannerWrapper from '../bannerhome.style';
import { Link } from 'gatsby';
import { background, color, left } from 'styled-system';
import Background from 'common/src/assets/image/hosting/ikons/icon1.png';
import Background1 from 'common/src/assets/image/hosting/ikons/ikon2.png';
import Background2 from 'common/src/assets/image/hosting/ikons/ikon3.png';
import Bannerbg from 'common/src/assets/image/hosting/ikons/Background.jpg';
import book from 'common/src/assets/image/hosting/ikons/book.png';
import workflow1 from 'common/src/assets/image/hosting/ikons/workflow1.png';
import boost2 from 'common/src/assets/image/hosting/ikons/boost2.png';
import coding3 from 'common/src/assets/image/hosting/ikons/coding3.png';
import ExampleDoc from 'common/src/assets/image/hosting/ikons/8-Hospitality-use-cases.pdf';
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'
// import Homepageimage from "common/src/assets/image/hosting/sliderv2.svg";

// import RAPBotImage from "common/src/assets/image/hosting/Pages/Misc/Checklist.jpg";
// import Achievementimage from "common/src/assets/image/hosting/news/home_banner_analytics.jpg";
// import Achievementimage2 from "common/src/assets/image/hosting/news/home_banner_cioreview.jpg";
// import TypingEffectSection from "../../TypingEffect";
// import { color, height } from "styled-system";

// import './bannerother.css';

const CampaignBanner = ({
  row,
  mainrow,
  red,
  col,
  title,
  description,
  subdescription,
  button,
  textArea,
  advrow,
  advcolimg,
  advcoltext,
  adtext,
  adtitle,
  imagecont,
  column,
  pipe,
}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact_number, setcontact_number] = useState('');
  const [message, setMessage] = useState('');
  const [shopStat, setshopChange] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [isError, setIsError] = useState(false);

  const emailRegex =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const validateEmail = (event) => {
    const email = event.target.value;
    setEmail(email);
    if (emailRegex.test(email)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };




  const validatephone = (number) => {
    const contact_number = number;
    console.log(number);
    setcontact_number(contact_number);
    console.log(contact_number);

  
    if (contact_number.length<5) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };
  function SubmitButton() {
    if (name && email && contact_number) {
      return (
        <button type="button" className="btnstyle">
          Button
        </button>
      );
    } else {
      return (
        <button type="button" className="btnstyle" disabled>
          Button
        </button>
      );
    }
  }
  const handleSubmit = async (e) => {
    window.open(ExampleDoc);

    e.preventDefault();
    try {
      let bodyRequest = {
        name: name,
        email: email,
        contact_number: contact_number,
        // website: 'campaign',
      };
      let res = await fetch('https://forms.rapidautomation.ai/api/saveform', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(bodyRequest),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setName('');
        setEmail('');
        setMessage('User created successfully');
      } else {
        setMessage('Some error occured');
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="  img5">
        <Container>
          <div className="home_box">
            <div class="flex-container1 rcen mty3 wrap1">
              <div className="">
                <p className="red7">FREE EBOOK</p>
                <p className="bookhead3 ">
                  How hospitality leaders can boost back-end accounting
                  operations with automation
                </p>
                <p className="phead5  ">
                  Identify high ROI-yielding use cases to attain peak
                  operational efficiencies and grow faster
                </p>
              </div>

              <div className=" mt7 mb5 mt5l">
                <div className="divcard7 divcard9 bgw">
                  <p className="cardp">Get Your Free Copy</p>



                  
                  <form onSubmit={handleSubmit}>
                  <input
                      className="inputstyle"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Name*"
                      required
                    />

                    <input
                      className="border-white"
                      type="email*"
                      value={email}
                      onChange={validateEmail}
                      placeholder="Work Email*"
                      required
                    />
  <p className='span1'>{isValid ?' ': 'Please Enter valid Email'}</p>
                    

  <PhoneInput
   
  country={'us'}
  value={contact_number}
  onChange={(number)=>validatephone(number)}
/>
{!isError ?' ': <p className='span3'>please Enter Valid Mobile Number</p>}
     

                  
                    <a
                      href="/static/8-Hospitality-use-cases-a735374c3a174fae548f3428c1525365.pdf"
                      download
                    >
                      <button
                        className="btnstyle1 f12"
                        onClick={() => setshopChange(!shopStat)}
                      >
                        Download Free Ebook
                      </button>
                    </a>
                    <p className="fs14">
                We respect your privacy. Your information is safe
                </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

<Container>

<div className='mfw mt3r'>
  
  

<div className="">
          <img src={book} className="book" />
        </div>
        <div className="">
          {/* <p className="">
            <b>This eBook will help you to</b>
          </p> */}
        <p className="w14"><b>This eBook will help you to</b></p>
          <span class="dot centercircle">
            <img src={workflow1} className="simg" />
          </span>

          <p className="w14">Identify use cases with high ROI</p>
        </div>

        <div className=" ">
        <p className="w14 cf">Identify use cases with high ROI</p>
          <span class="dot  centercircle">
            <img src={boost2} className=" simg" />
          </span>

          <p className=" w14">
            Understand how hyper- automation can boost your hospitality
            enterprise growth
          </p>
        </div>

        <div className=" ">
        <p className="w14 cf ">Identify use cases with high ROI</p>
          <span class="dot centercircle">
            <img src={coding3} className="simg" />
          </span>

          <p className="w14">
            Know how your competition is benefitting by automating back-end
            processes
          </p>
        </div>

</div>
</Container>
      {/* <div class="  flexcons  g5 mt24  justify">
        <p className="">
          <img src={book} className="book" />
        </p>
        <p className="ml55 mt2">
          <p className="">
            <b>This eBook will help you to</b>
          </p>

          <span class="dot ml3">
            <img src={workflow1} className="simg" />
          </span>

          <p className="smw16">Identify use cases with high ROI</p>
        </p>

        <p className=" ml55 mt2  gg5 mpl">
          <span class="dot ml3 mtr4">
            <img src={boost2} className=" simg" />
          </span>

          <p className=" smw16">
            Understand how hyper- automation can boost your hospitality
            enterprise growth
          </p>
        </p>

        <p className="ml55 mt2 mpl gg5 ml56">
          <span class="dot ml3 mtr4">
            <img src={coding3} className="simg" />
          </span>

          <p className="smw16">
            Know how your competition is benefitting by automating back-end
            processes
          </p>
        </p>
      </div> */}
    </>
  );
};

CampaignBanner.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  subdescription: PropTypes.object,
  button: PropTypes.object,
  red: PropTypes.object,
};

CampaignBanner.defaultProps = {
  red: {
    fontfamily: 'Open Sans',
    fontsize: '50px',

    color: 'red',
  },
  mainrow: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: [1, '100%', '100%', '100%', '100%'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: ['100%', '100%', '50%', '50%', '50%'],
    position: 'relative',
  },
  column: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: ['100%', '100%', '100%', '100%', '100%'],
    pt: ['10px', '10px', '75px', '75px', '75px'],
    position: 'relative',
  },
  textArea: {
    width: [1, '100%', '100%', '100%', '100%'],
  },
  title: {
    as: 'h1',
    fontSize: ['26px', '26px', '26px', '26px', '26px'],
    fontWeight: '700',
    // color: '#0f2137',
    // color: "#fff",
    color: '#000000',
    letterSpacing: '-0.025em',
    mt: ['20px', '20px', '25px', '25px', '25px'],
    // mb: ['20px', '20px', '25px', '25px', '25px'],
    mb: 0,
    lineHeight: '1.31',
    textAlign: 'center',
  },
  description: {
    as: 'h2',
    fontSize: ['26px', '26px', '26px', '26px', '26px'],
    // color: '#343d48cc',
    // color: "#fff",
    color: '#000000',
    lineHeight: '1.2',
    // mb: ['8px', '8px', '10px', '10px', '10px'],
    mb: '2px',
    textAlign: 'center',
  },
  subdescription: {
    fontSize: ['14px', '14px', '14px', '16px', '16px'],
    // color: '#343d48cc',
    color: '#fff',
    lineHeight: '1.75',
    mb: ['20px', '20px', '25px', '25px', '25px'],
    textAlign: 'center',
  },
  button: {
    title: 'Search',
    type: 'button',
    fontSize: '18px',
    fontWeight: '500',
    color: '#fff',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    iconPosition: 'left',
  },
  cler: {
    color: 'red',
  },
  advrow: {
    mt: '15px',
    mb: '20px',
    width: '70%',
    flexBox: true,
    flexWrap: 'wrap',
    minHeight: '100px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  advcolimg: {
    width: ['100%', '100%', '100%', '50%', '50%'],
    height: '100%',
  },
  advcoltext: {
    width: ['100%', '100%', '100%', '50%', '50%'],
    p: '10px',

    flexBox: true,
    alignItems: 'center',
  },
  adtitle: {
    fontSize: ['12px', '12px', '12px', '12px', '12px'],
    fontWeight: 'bold',
    // color: '#0f2137',
    color: '#000000',
    // letterSpacing: "-0.025em",
    lineHeight: '1.31',
    textAlign: ['center', 'center', 'left', 'left', 'left'],
    m: '0px !important',
  },
  adtext: {
    fontSize: ['16px', '16px', '16px', '16px', '16px'],
    fontWeight: 'normal',
    // color: '#0f2137',
    color: '#393E99',
    letterSpacing: '-0.025em',
    lineHeight: '1.31',
    textAlign: ['center', 'center', 'left', 'left', 'left'],
  },
  imagecont: {
    // position: "absolute",
    // ml: "-150px",
  },
  pipe: {
    borderLeft: ['none', 'none', 'none', '1px solid grey', '1px solid grey'],
  },
};

export default CampaignBanner;
